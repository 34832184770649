<template>
    <div :class="`${$isMobile() ? '' : 'v'}h-100 flex-fill position-relative d-flex flex-column auth-page`">

        <img src="@/static/dog-left-right.png" class="position-absolute w-100" :style="`max-width:150px;bottom:${$isMobile() ? '' : '12'}0px`" />
        <img src="@/static/dog-right-left.png" class="position-absolute w-100" style="max-width:150px;right:0;top: 120px;" />

        <div class="position-absolute" style="top:30px" v-if="$isMobile()">
            <img src="@/static/logo.png" style="max-width:250px" />
            <h1 class="text-white h5 ml-2" style="font-weight: normal;margin-top:-1em">All Your Pet Needs In Your Hand</h1>
        </div>

        <div :class="`${$isMobile() ? 'my' : 'm'}-auto w-100`" :style="`${$isMobile() ? '' : 'max-width:500px'}`">
            <div class="px-3">
                <h1 class="text-white">Login</h1>
                <hr/>
                <div class="input-group">
                    <span class="input-group-text"><i class="bx bxs-user-circle"></i></span>
                    <input type="text" class="form-control" placeholder="Email" v-model="email" >
                </div>
                <div class="badge badge-danger text-white" v-if="!valid.email && isSubmit" style="margin-top: .5em;">Username is not valid</div>

                <div class="input-group mt-1">
                    <span class="input-group-text"><i class="bx bxs-lock-alt"></i></span>
                    <input type="password" class="form-control" placeholder="Password" v-model="password">
                </div>
                <div class="badge badge-danger text-white" v-if="!valid.password && isSubmit" style="margin-top: .5em;">Password is not valid</div>

                <div class="d-flex flex-wrap align-items-center mt-1" style="gap:10px">
                    <div class="text-white mr-auto h5 mb-0 font-weight-normal" style="max-width: 200px;">
                        Don't Have Account Yet? 
                        <router-link to="/register" class="font-weight-bolder text-white">Sign Up Here!</router-link>
                    </div>
                    <button class="btn text-white" style="background-color: #187294;" @click="onSubmit()">
                        Login
                    </button>
                </div>
            </div>
        </div>

        <!-- <div class="position-absolute w-100 d-flex flex-column  px-3 justify-content-center" style="bottom: 80px;gap:10px">
            <button class="btn text-white flex-fill" style="background-color: #187294;">
                Sign In Using Google
            </button>
            <button class="btn text-white flex-fill" style="background-color: #187294;">
                Sign In Using Apple ID
            </button>
            <button class="btn text-white flex-fill" style="background-color: #187294;">
                Sign In Using Facebook
            </button>
        </div> -->

    </div>
</template>

<script>

import store from '@/store';
import Cookies from 'universal-cookie'  
const cookies = new Cookies()

export default {
    computed: {
        valid(){
            return {
                email: this.email?.length > 0,
                password: this.password?.length > 0
            }
        },
        allValid(){
            let keys = Object.keys(this.valid)
            let valid = true
            keys.forEach(item => {
                valid = valid && this.valid[item]
            })
            return valid
        }
    },
    methods: {
        onSubmit(){
            this.isSubmit = true
            let payload = {
                email: this.email,
                password: this.password
            }
            if(this.allValid)
            store.dispatch('auth/Login', payload)
            // this.$router.push('/register')
        }
    },
    mounted(){
        if(cookies.get('token')){
            this.$router.push('homepage')
        }
    },
    data(){
        return {
            email: '',
            password: '',
            isSubmit: false
        }
    }
}

</script>

<style>
    .auth-page .btn {
        border-radius: 20px;
    }
    .input-group span {
        border-right: unset;
        border-radius: 10px 0 0 10px;
        font-size: 24px;
        color: #1F9BCD;
    }
    .input-group input {
        margin-left: 0;
        padding-left: 0;
        border-left: unset;
        border-radius: 0 10px 10px 0;
        height: 50px;
        background-color: white !important;
    }
</style>